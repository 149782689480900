import type { KeyboardEvent } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Form, Link, useSearchParams } from '@remix-run/react';
import { PassphraseInput } from './passphrase-input';
import { useRootLoaderData } from '~/root';
import { PrimaryButton } from './primary-button';
import { CopyTextButton } from './copy-text-button';
import Modal from './modal';
import { twMerge } from 'tailwind-merge';
import { authenticateForDesktop } from '~/services/desktop-auth.client';
import invariant from 'tiny-invariant';

export function DesktopAuthModal({ onClose }: { onClose: () => void }) {
  const { user } = useRootLoaderData();
  invariant(user, 'User is required');

  const [searchParams, setSearchParams] = useSearchParams();

  const [token, setToken] = useState<string | null>();

  const [loading, setLoading] = useState(false);
  const [passphraseError, setPassphraseError] = useState(false);
  const [passphraseErrorMsg, setPassphraseErrorMsg] = useState('');
  const [passphrase, setPassphrase] = useState('');
  const continueRef = useRef<HTMLButtonElement | null>(null);

  const isAuthenticated = Boolean(token);

  useEffect(() => {
    const fetchClientData = async () => {
      const loginKey = sessionStorage.getItem('loginKey');
      invariant(loginKey, 'Login key is required');

      const data = await authenticateForDesktop({
        email: user.email,
        saltEnc: user.saltEnc,
        encDriverKey: user.encDriverKey,
        encryptionEnabled: user.encryptionEnabled,
        encPrivateKey: user.encPrivateKey,
        encSymmetricKey: user.encSymmetricKey,
        loginKey,
      });

      if ('errors' in data) {
        setPassphraseError(true);
        setPassphraseErrorMsg(data.errors?.[0]);
        return;
      }

      if ('token' in data) {
        setToken(data.token);
        setLoading(false);
        sessionStorage.removeItem('loginKey');
        searchParams.forEach((param) => searchParams.delete(param));
        setSearchParams(searchParams);
        return;
      }
    };

    if (!user?.encryptionEnabled) {
      return;
    }

    fetchClientData();
  }, [searchParams, setSearchParams, user]);

  const handleContinue = async () => {
    const loginKey = sessionStorage.getItem('loginKey');
    invariant(user, 'User is required');
    invariant(loginKey, 'Login key is required');

    if (!passphrase) {
      setPassphraseError(true);
      setPassphraseErrorMsg('Passphrase must be provided');
      return;
    }

    const data = await authenticateForDesktop({
      email: user.email,
      saltEnc: user.saltEnc,
      passphrase,
      encDriverKey: user.encDriverKey,
      encryptionEnabled: user.encryptionEnabled,
      encPrivateKey: user.encPrivateKey,
      encSymmetricKey: user.encSymmetricKey,
      loginKey,
    });

    if ('errors' in data) {
      setPassphraseError(true);
      setPassphraseErrorMsg(data.errors?.[0]);
      setLoading(false);
      return;
    }

    if ('token' in data) {
      setToken(data.token);
      setLoading(false);
      resetLoginKeyAndParams();
      return;
    }
  };

  const handleLaunch = () => {
    const appProtocol = 'insomnia';
    const insomniaAppURI = new URL(`${appProtocol}://app/auth/finish?box=${token}`);
    window.location.assign(insomniaAppURI);
    resetLoginKeyAndParams();
    onClose();
  };

  const resetLoginKeyAndParams = () => {
    sessionStorage.removeItem('loginKey');
    searchParams.forEach((param) => searchParams.delete(param));
    setSearchParams(searchParams);
  };

  if (user?.encryptionEnabled) {
    return (
      <Modal isOpen={true} title="Launch Insomnia desktop" onCancel={onClose} className="max-w-[600px]">
        <div className="flex flex-col">
          {!passphraseError && (
            <p className="m-0 text-[14px] font-normal leading-[18px] tracking-[-0.25px] text-[#000000d9]">
              You are successfully authenticated. You can click Launch button or manually enter the token.
            </p>
          )}
          {passphraseError && (
            <p className="m-0 text-[14px] font-normal leading-[18px] tracking-[-0.25px] text-[#000000d9]">
              Something went wrong. Please contact the support.
            </p>
          )}
          <div className={twMerge('flex items-end justify-between', passphraseError ? 'mt-[32px]' : 'mt-[16px]')}>
            <div className="flex gap-[10px]">
              <PrimaryButton type="button" onPress={handleLaunch} isDisabled={!isAuthenticated} className="w-[200px]">
                Launch
              </PrimaryButton>
            </div>
            <CopyTextButton value={token}>Manually enter token</CopyTextButton>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={true}
      title={!isAuthenticated ? 'Enter your existing passphrase' : 'Launch Insomnia desktop'}
      className="max-w-[600px]"
      hideCloseButton={isAuthenticated}
      onCancel={() => {
        resetLoginKeyAndParams();
        onClose();
      }}
    >
      <div className="flex flex-col">
        <p className="m-0 text-[14px] font-normal leading-[18px] tracking-[-0.25px] text-[#000000d9]">
          {!isAuthenticated
            ? 'Enter the passphrase that you have created when you signed up on Insomnia, which is being used to secure your data with end-to-end encryption (E2EE).'
            : 'You are successfully authenticated. You can click Launch button or manually enter the token.'}
        </p>

        {!isAuthenticated && (
          <Form name="passphraseForm" className="mt-[20px]">
            <PassphraseInput
              name="passphrase"
              autoFocus
              onChangePassphrase={setPassphrase}
              isInvalid={passphraseError}
              errorMessage={passphraseError ? passphraseErrorMsg : undefined}
              onInput={() => {
                if (passphraseError) {
                  setPassphraseError(false);
                  setPassphraseErrorMsg('');
                }
              }}
              onKeyDown={async (e: KeyboardEvent) => {
                if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                  e.preventDefault();
                  e.stopPropagation();
                  handleContinue();
                  continueRef.current?.focus();
                }
              }}
            />
            <div className="mt-[16px] flex items-end justify-between">
              <div className="flex gap-[10px]">
                <PrimaryButton type="button" loading={loading} className="w-[200px]" onPress={handleContinue}>
                  Continue
                </PrimaryButton>
              </div>
              <Link
                to="/app/authorize/forgot-passphrase"
                style={{
                  fontSize: 14,
                  color: 'rgba(0, 0, 0, 0.5)',
                  textDecoration: 'none',
                }}
              >
                Forgot passphrase
              </Link>
            </div>
          </Form>
        )}
        {isAuthenticated && (
          <div className={twMerge('flex items-end justify-between', passphraseError ? 'mt-[32px]' : 'mt-[16px]')}>
            <div className="flex gap-[10px]">
              <PrimaryButton type="button" onPress={handleLaunch} className="w-[200px]">
                Launch
              </PrimaryButton>
            </div>
            <CopyTextButton value={token}>Manually enter token</CopyTextButton>
          </div>
        )}
      </div>
    </Modal>
  );
}
